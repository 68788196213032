import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Grid,
  Chip,
  TextareaAutosize,
  CircularProgress,
  IconButton
} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from "@mui/material/Checkbox"; // ...
// import CircularProgress  from '@mui/material/CircularProgress';
import axios from "axios";
// import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import RatingInput from "../../../components/Rating/RatingInput";
import FileViewerComponent from "../../../components/FileViewer/FileViewerComponent";
// import { Document, Page } from 'react-pdf'
import MyPdfViewer from "./MyPdfViewer";
import Snack from "../../../components/snackbar/Snack";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link, useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  input: {
    border: "1px solid yellow",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    "& .MuiInputBase-root": {
      backgroundColor: "#161A24",
      borderRadius: "10px",
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  backbtn: {
    borderRadius: "10px",
    fontSize: "14px",
    color: "#E3A700",
    height: "40px",
    marginTop: "10px",
    fontVariant: "small-caps",
    background: "#404040",
    border: "2px solid #fff",
    marginRight: "20px",
    boxShadow: "5px 5px 10px #000",
    width: "230px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "#404040",
      cursor: "pointer",
    },
  },
  btn: {
    height: "40px",
    width: "230px",
    fontWeight: "700",
    borderRadius: "10px",
    marginTop: "10px",
    marginRight: "20px",
    color: "#000",
    fontVariant: "small-caps",
    background: "#E3A700",
    border: "2px solid #fff",
    fontSize: "14px",
    boxShadow: "5px 5px 10px #000",
    fontVariant: "small-caps",
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#E3A700",
      cursor: "pointer",
    },
  },
  label: {
    marginTop: "10px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "black",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
  rowBox: {
    display: "flex",
    // justifyContent:"space-between",
    // alignItems:"center",
    // width: "30%",
  },
  labelHeader: {
    fontSize: "25px",
    fontWeight: "bold",
    color: "black",
    paddingBottom: "5px",
    textShadow: "5px 5px 10px #000",
  },
}));
const CreateInvoice2 = (props) => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [PDFstates, setPDFstates] = useState({
    clientName: "",
    clientEmail: "",
    clientAddress: "",
    clientcountry: "",
    clientcity: "",
    clientphone: "",
    companyName: "Zenon Wholesale Digital Marketing",
    companycountry: "United States",
    companycity: "Denver, CO 80260",
    companyEmail: "ron@zenonwholesaledigitalmarketing.com",
    companyAddress: "1643 Aspen Meadows Circle ",
    companyphone: "(303) 725-3007",
    Discription:
      "Exchange Policy ( 14 days ) 1-3 are eligible for an exchange or credit\r\n\n1.\tIf the information provided to the law firm does not match what was told to our intake on the call.\r\n2.\tIf the retainer is unresponsive after 5-7 days & then we are given 7 days to try to get them back\n\t  on a call and are still unresponsive.\r\n3.\tIf the lead is not interested in pursuing the claim.",
    beneficiaryName: "Zenon Interactive LLC",
    chaseBank: "JP Morgan Chase, 3rd Floor, Tampa, FL 33160",
    ZenonAC: "792969310",
    ZenonRN: "021000021",
    beneficiaryBankName: "JP Morgan Chase",
    Quantity: "",
    UnitPrice: "",
    Amount: "",
  });
  console.log(PDFstates.Discription, "PDFstates");
  const [products, setProducts] = useState([]);
  const [formDatac, setFormDataC] = useState({
    companyName: [],
    retainerProducts: [],
  });
  const [totalPrice, setTotalPrice] = useState(0);

  //   1. If the information provided to the law firm does not match what was told to our intake on the call.
  // 2. If the retainer is unresponsive after 5-7 days & then we are given 7 days to try to get them back on a call and are still unresponsive.,
  // 3. If the lead not interested in pursuing the claim.
  const [logoSrc, setLogoSrc] = useState("/assets/images/Cp.png");
  // State variable to hold the input value
  const [newSrc, setNewSrc] = useState("");

  // Function to handle changes to the logo source
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoSrc(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const categoryData = [
    {
      _id: "659008e39db2202de0e44507",
      userId: "654235f9ee800e53b4155849",
      productName: "Premium Connected TV ONLY (CTV)",
    },
    {
      _id: "659008e39db0902de0e44569",
      userId: "654235f9ee800e53b5155849",
      productName: "Digital Video Blend",
    },
    {
      _id: "655608e39db2202de0e44569",
      userId: "654235f9ee800e53b5155849",
      productName: "Online Video",
    },
    {
      _id: "159008e39db2202de0e44569",
      userId: "654235f9ee800e53b5155849",
      productName: "Streaming Radio",
    },
    {
      _id: "099008e39db2202de0e44569",
      userId: "654235f9ee800e53b5155849",
      productName: "Geofencing",
    },
    {
      _id: "659055e39db2202de0e4476h6",
      userId: "659008e39db2202de1e44569",
      productName: "Geofencing + Retargeting",
    },
    {
      _id: "659055e39db2202de0e44512",
      userId: "656608e39db2202de0e44569",
      productName: "Geofencing + Data bits+ Retargeting",
    },
    {
      _id: "659055e39db2202de0e44569",
      userId: "654235f9ee800e53b5155849",
      productName: "Geofencing Video",
    },
    {
      _id: "659118e39db2202de0e44569",
      userId: "654235f9ee800e53b5155849",
      productName: "Advanced Email 30k - 49,999",
    },
    {
      _id: "659009739db2202de0e44569",
      userId: "654235f9ee800e53b5155849",
      productName: "Advanced Email 50k+",
    },
    {
      _id: "609008e39db2202de0e44569",
      userId: "654235f9ee800e53b5155849",
      productName: "Banners Awareness",
    },
    {
      _id: "659008e39db2202de0e44560",
      userId: "654235f9ee800e53b5155849",
      productName:
        "Banners Keyword, Contextual, Demo/Behavioral Site Retargeting",
    },
  ];

  const [sections, setSections] = useState([
    { id: 1, Description: "", Quantity: "", UnitPrice: "" },
  ]);
  const [item, setItem] = useState([]);
  const totalAmount = 0;
  const qty = 0;
  const price = 0;
  const formattedTotalAmount = 0;
  const handleChangeItem = (event, index) => {
    const { name, value } = event.target;
    console.log(name, value)
    // if (name === "Quantity") {
    //   qty = value;
    // } else if (name === "UnitPrice") {
    //   price = value;
    //   totalAmount += qty * price;
    //   formattedTotalAmount = totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    // }

    setSections((prevSections) => {
      return prevSections.map((section, idx) =>
        idx === index ? { ...section, [name]: value } : section
      );
    });
  };

  useEffect(() => {
    // Calculate the total price whenever sections change
    const calculateTotalPrice = () => {
      let total = 0;
      sections.forEach(item => total += Number(item.Quantity * item.UnitPrice));
      setTotalPrice(total);
    };

    calculateTotalPrice();
  }, [sections]);

  const handleAddSection = () => {
    setSections((prevSections) => [
      ...prevSections,
      {
        id: prevSections.length + 1, // Ensure unique ID
        Description: "",
        Quantity: "",
        UnitPrice: "",
      },
    ]);
  };

  const handleRemoveSection = (index) => {
    setSections((prevSections) => prevSections.filter((_, idx) => idx !== index));
  };

  var currentDate = new Date();

  // Define months array for formatting
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function generateRandomString(length) {
    const numbers = "0123456789".split("");
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    let result = "";

    // Shuffle arrays
    shuffleArray(numbers);
    shuffleArray(characters);

    for (let i = 0; i < length; i++) {
      // Alternate between numbers and characters
      if (i % 2 === 0) {
        result += numbers.pop(); // Remove the last element from numbers array
      } else {
        result += characters.pop(); // Remove the last element from characters array
      }
    }
    return result;
  }
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
  }

  // Format the date
  var formattedDate =
    months[currentDate.getMonth()] +
    " " +
    currentDate.getDate() +
    ", " +
    currentDate.getFullYear();
  console.log("sections", sections)

  const [invoiceData, setInvoiceData] = useState({
    invoiceNumber: generateRandomString(8),
    dateDue: formattedDate,
    items: sections,
    totalAmount: PDFstates.Amount,
  });
  console.log("invoiceData", invoiceData.items)
  // const centerText = (doc, text, y, fontWeight = "normal") => {
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const textWidth = doc.getTextWidth(text);
  //   const textX = (pageWidth - textWidth) / 2;
  //   doc.setFont(fontWeight === "bold" ? "Helvetica" : "Helvetica", fontWeight);
  //   doc.text(text, textX, y);
  // };

  const generatePDF = async () => {
    const doc = new jsPDF();
    const docWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let cursorY = 8;

    // Calculate the width of the text
    const textWidth1 = (doc.getStringUnitWidth(`Invoice Number: ${invoiceData.invoiceNumber}`) * doc.internal.scaleFactor) / 24; // Assuming font size 12
    const textWidth2 = (doc.getStringUnitWidth(`Date Due: ${invoiceData.dateDue}`) * doc.internal.scaleFactor) / 24; // Assuming font size 12

    // Get the maximum width between the two text elements
    const textWidth = Math.max(textWidth1, textWidth2);

    // Calculate the x-coordinate for the right-aligned text
    const rightAlignedX = docWidth - 45 - textWidth;

    // Add logo
    doc.addImage(logoSrc, "SVG", rightAlignedX, cursorY, 40, 20);
    cursorY += 8; // Update cursor position

    // Add Invoice Header
    doc.setFontSize(20);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice", 14, cursorY);
    cursorY += 8;

    // Invoice Number and Date Due
    doc.setFontSize(12);
    doc.text(`Invoice Number: ${invoiceData.invoiceNumber}`, 14, cursorY);
    cursorY += 6;
    doc.setFont("helvetica", "normal");
    doc.text(`Date Due: ${invoiceData.dateDue}`, 14, cursorY);
    cursorY += 10;

    // Sender Details
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text(`${PDFstates.companyName}`, 14, cursorY);
    cursorY += 6;
    doc.setFont("helvetica", "normal");
    doc.text(`${PDFstates.companyAddress}`, 14, cursorY);
    cursorY += 6;
    doc.text(`${PDFstates.companycity}`, 14, cursorY);
    cursorY += 6;
    doc.text(`${PDFstates.companycountry}`, 14, cursorY);
    cursorY += 6;
    doc.text(`Phone: ${PDFstates.companyphone}`, 14, cursorY);
    cursorY += 6;
    doc.text(`Email: ${PDFstates.companyEmail}`, 14, cursorY);
    cursorY += 10;

    // Calculate total prices
    let tprices = 0;
    sections.forEach(item => tprices += Number(item.Quantity * item.UnitPrice));
    // console.log(tprices.toLocaleString('en-US', { style: 'currency', currency: 'USD' }), "Test")
    // Receiver Details
    doc.setFont("helvetica", "bold");
    doc.text("Bill To:", docWidth - 80, cursorY - 45);
    doc.text(`${PDFstates.clientName}`, docWidth - 80, cursorY - 40);
    doc.setFont("helvetica", "normal");
    doc.text(`${PDFstates.clientAddress}`, docWidth - 80, cursorY - 34);
    doc.text(`${PDFstates.clientcity}`, docWidth - 80, cursorY - 28);
    doc.text(`${PDFstates.clientcountry}`, docWidth - 80, cursorY - 22);
    doc.text(`Phone: ${PDFstates.clientphone}`, docWidth - 80, cursorY - 16);
    doc.text(`Email: ${PDFstates.clientEmail}`, docWidth - 80, cursorY - 10);

    doc.setDrawColor(211, 211, 211);
    doc.setLineWidth(0.5);
    doc.line(8, cursorY - 8, docWidth - 8, cursorY - 8);

    // Amount Due
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text(`${tprices.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} USD due ${invoiceData.dateDue}`, 14, cursorY);
    cursorY += 10;

    // Thank you message
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Thank you for your business!", 14, cursorY);
    cursorY += 10;

    // Add a table for items
    if (cursorY + 10 > pageHeight) {
      doc.addPage();
      cursorY = 10;
    }
    doc.autoTable({
      startY: cursorY,
      head: [["Description", "Qty", "Unit Price", "Amount"]],
      body: sections.map((item) => [
        item.Description,
        item.Quantity,
        `$${Number(item.UnitPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
        `$${(item.Quantity * item.UnitPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      ]),
    });

    cursorY = doc.lastAutoTable.finalY + 6;

    // Subtotal and Total
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Subtotal:`, 125, cursorY + 6);
    doc.text(`Total:`, 125, cursorY + 12);
    doc.setFont("helvetica", "bold");
    doc.text(`Amount Due:`, 125, cursorY + 20);
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${tprices.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, 153, cursorY + 6);
    doc.text(`${tprices.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, 153, cursorY + 12);
    doc.setFont("helvetica", "bold");
    doc.text(`${tprices.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, 153, cursorY + 20);

    cursorY += 32;

    doc.setTextColor(128, 128, 128); // Grey text
    const privacyText = "**We will never sell or share your information with any 3rd party**";
    const privacyTextWidth = doc.getStringUnitWidth(privacyText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const privacyX = (docWidth - privacyTextWidth) / 2;
    doc.text(privacyText, privacyX, cursorY);
    cursorY += 4;

    // Divider
    doc.setDrawColor(211, 211, 211);
    doc.setLineWidth(0.5);
    doc.line(14, cursorY, docWidth - 14, cursorY);
    cursorY += 6;

    // Exchange Policy
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 255);
    doc.setTextColor(0, 0, 0);
    doc.text(PDFstates.Discription, 20, cursorY);
    cursorY += 26;

    // Warning Message
    doc.setTextColor(255, 0, 0);
    doc.text(
      "***These are leads/retainers not cases, medical records are not guaranteed and not part of the exchange policy***",
      14,
      cursorY
    );
    cursorY += 2;
    // Divider
    doc.setDrawColor(211, 211, 211);
    doc.setLineWidth(0.5);
    doc.line(14, cursorY, docWidth - 14, cursorY);
    cursorY += 10;



    // Footer Information
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    centerText(doc, `${PDFstates.beneficiaryName}`, cursorY, "bold");
    cursorY += 5;
    centerText(doc, "Dba", cursorY, "bold");
    cursorY += 5;
    centerText(doc, "Zenon Wholesale Digital Marketing", cursorY, "bold");
    cursorY += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(255, 0, 0);
    centerText(doc, "Incoming Wiring Instructions:", cursorY);

    cursorY += 6;
    if (sections.length > 2) {
      doc.setTextColor(0, 0, 0);
      centerText(doc, `Beneficiary Name: ${PDFstates.beneficiaryName}`, cursorY, "bold");
      cursorY += 8;
      doc.setFontSize(12);
      centerText(doc, `Bank: ${PDFstates.beneficiaryBankName}`, cursorY, "bold");
      cursorY += 8;
      doc.setFontSize(12);
      centerText(doc, `Account Number: ${PDFstates.ZenonAC}`, cursorY, "bold");
      cursorY += 8; // Adjusted cursorY to account for merging
      doc.setFontSize(12);
      centerText(doc, `Routing Number: ${PDFstates.ZenonRN}`, cursorY, "bold");
      cursorY += 8; // Adjusted cursorY to account for merging
      doc.setFontSize(12);
      centerText(doc, `Bank Address: ${PDFstates.chaseBank}`, cursorY, "bold");
    } else {

      doc.setTextColor(0, 0, 0);
      centerText(doc, `Beneficiary Name:`, cursorY, "bold");
      cursorY += 4;
      doc.setFontSize(10);
      centerText(doc, `${PDFstates.beneficiaryName}`, cursorY);
      cursorY += 8;
      doc.setFontSize(12);
      centerText(doc, `Bank:`, cursorY, "bold");
      cursorY += 4;
      doc.setFontSize(10);
      centerText(doc, `${PDFstates.beneficiaryBankName}`, cursorY);
      cursorY += 8;
      doc.setFontSize(12);
      centerText(doc, `Account Number:`, cursorY, "bold");
      cursorY += 4;
      doc.setFontSize(10);
      centerText(doc, `${PDFstates.ZenonAC}`, cursorY);
      cursorY += 8;
      doc.setFontSize(12);
      centerText(doc, `Routing Number:`, cursorY, "bold");
      cursorY += 4;
      doc.setFontSize(10);
      centerText(doc, `${PDFstates.ZenonRN}`, cursorY);
      cursorY += 8;
      doc.setFontSize(12);
      centerText(doc, `Bank Address: `, cursorY, "bold");
      cursorY += 4;
      doc.setFontSize(10);
      doc.setLineWidth(0.5);
      centerText(doc, `${PDFstates.chaseBank}`, cursorY);
    }


    doc.save("invoice.pdf");

    // Upload generated file
    // await uploadFile("invoice.pdf");
  };

  const centerText = (doc, text, y, style) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const x = (pageWidth - textWidth) / 2;
    if (style) doc.setFont(undefined, style);
    doc.text(text, x, y);
  };

  const uploadFile = async (filename) => {
    try {
      const formData = new FormData();
      formData.append("file", filename);

      const response = await fetch("YOUR_UPLOAD_URL", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };





  const handleChange = (e) => {
    const { name, value } = e.target;
    setPDFstates({ ...PDFstates, [name]: value });
  };
  const handleChangeProduct = (event) => {
    console.log(event);
    const {
      target: { value },
    } = event;
    setProducts(value);
  };
  const ViewPDF = () => {
    // navigate("/dashboard/pdf-view");
  }
  const classes = useStyles();
  return (
    <div style={{ marginTop: "-40px", padding: "60px", backgroundColor: "#A6CAEC" }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button className={classes.btn} onClick={generatePDF}>
          Generate PDF
        </Button>
        {/* <Button className={classes.btn} onClick={ViewPDF}>
          View PDF
        </Button> */}
      </Box>

      <Box sx={{ mt: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={6}>
            <Box className={classes.rowBox}>
              <Typography variant="h4" className={classes.labelHeader}>
                Invoice
              </Typography>
            </Box>
            <Box className={classes.rowBox}>
              <Typography
                className={classes.label}
                sx={{ marginRight: "5.5em" }}
              >
                Invoice Number
              </Typography>
              <Typography className={classes.label}>
                {invoiceData.invoiceNumber}
              </Typography>
            </Box>
            <Box className={classes.rowBox}>
              <Typography
                className={classes.label}
                sx={{ marginRight: "8.5em" }}
              >
                Date Due
              </Typography>
              <Typography className={classes.label}>
                {invoiceData.dateDue}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <img src={logoSrc} alt="My Icon" width={"20%"} />
            <TextField
              fullWidth
              required
              className={classes.input}
              multiple={false}
              type="file"
              onChange={handleFileChange}
              accept="image/*"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6} sx={{ marginTop: "40px" }}>
          <Typography className={classes.label} sx={{ marginRight: "8.5em" }}>
            Company Name
          </Typography>
          <TextField
            name="companyName"
            className={classes.input}
            value={PDFstates.companyName}
            onChange={handleChange}
            fullWidth
            sx={{ marginRight: "5.5em" }}
          />
          <Typography className={classes.label} sx={{ marginRight: "8.5em" }}>
            Company Address
          </Typography>
          <TextField
            className={classes.input}
            name="companyAddress"
            value={PDFstates.companyAddress}
            onChange={handleChange}
            fullWidth
          ></TextField>
          <TextField
            name="companycity"
            className={classes.input}
            value={PDFstates.companycity}
            onChange={handleChange}
            fullWidth
            sx={{ marginRight: "5.5em" }}
          />
          <TextField
            name="companycountry"
            className={classes.input}
            value={PDFstates.companycountry}
            onChange={handleChange}
            fullWidth
            sx={{ marginRight: "5.5em" }}
          />
          <Typography className={classes.label} sx={{ marginRight: "8.5em" }}>
            Company Phone
          </Typography>
          <TextField
            name="companyphone"
            className={classes.input}
            fullWidth
            value={PDFstates.companyphone}
            onChange={handleChange}
            sx={{ marginRight: "5.5em", marginTop: "10px" }}
          />
          <Typography className={classes.label} sx={{ marginRight: "8.5em" }}>
            Company Email
          </Typography>
          <TextField
            name="companyEmail"
            className={classes.input}
            fullWidth
            value={PDFstates.companyEmail}
            onChange={handleChange}
            sx={{ marginRight: "5.5em", marginTop: "10px" }}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Typography
            className={classes.label}
            sx={{ marginRight: "8.5em", fontWeight: "bold" }}
          >
            Bill To
          </Typography>
          <Typography className={classes.label} sx={{ marginRight: "8.5em" }}>
            Client Name
          </Typography>
          <TextField
            name="clientName"
            value={PDFstates.clientName}
            onChange={handleChange}
            className={classes.input}
            fullWidth
            sx={{ marginRight: "5.5em" }}
          />
          <Typography className={classes.label} sx={{ marginRight: "8.5em" }}>
            Client Address
          </Typography>
          <TextField
            className={classes.input}
            name="clientAddress"
            value={PDFstates.clientAddress}
            onChange={handleChange}
            placeholder="Client Street Address"
            fullWidth
          ></TextField>
          <TextField
            className={classes.input}
            name="clientcity"
            value={PDFstates.clientcity}
            onChange={handleChange}
            placeholder="Client City"
            fullWidth
          ></TextField>
          <TextField
            className={classes.input}
            name="clientcountry"
            value={PDFstates.clientcountry}
            onChange={handleChange}
            placeholder="Client Country"
            fullWidth
          ></TextField>
          <Typography className={classes.label} sx={{ marginRight: "8.5em" }}>
            Client Phone
          </Typography>
          <TextField
            name="clientphone"
            className={classes.input}
            fullWidth
            value={PDFstates.clientphone}
            onChange={handleChange}
            sx={{ marginRight: "5.5em", marginTop: "10px" }}
          />
          <Typography className={classes.label} sx={{ marginRight: "8.5em" }}>
            Client Email
          </Typography>
          <TextField
            name="clientEmail"
            value={PDFstates.clientEmail}
            onChange={handleChange}
            className={classes.input}
            fullWidth
            sx={{ marginRight: "5.5em", marginTop: "10px" }}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "40px" }}>
        <Typography
          sx={{ marginRight: "8.5em", fontWeight: "bold", fontSize: "26px" }}
        >
          {totalPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || "$0.00"}{" "}
          Due {invoiceData.dateDue}
        </Typography>
      </Box>
      <Box sx={{ marginTop: "40px" }}>
        <Typography sx={{ marginRight: "8.5em", fontSize: "16px" }}>
          Thank You for your business{" "}
        </Typography>
        <Typography sx={{ marginRight: "8.5em", fontSize: "16px" }}>
          We will never sell or share your information with any 3rd party{" "}
        </Typography>
      </Box>
      {sections.map((section, index) => (
        <Box
          key={section.id}
          sx={{
            marginTop: "20px",
            marginRight: "8.5em",
            fontSize: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // Align items center to vertically align the button with the fields
            gap: "16px"
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Typography>Description</Typography>
            <TextField
              className={classes.input}
              fullWidth
              name="Description"
              value={section.Description}
              onChange={(event) => handleChangeItem(event, index)}
            />
          </Box>
          <Box
            sx={{
              width: "50%",
              marginRight: "8.5em",
              fontSize: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography>Qty</Typography>
              <TextField
                className={classes.input}
                sx={{ margin: "10px" }}
                fullWidth
                name="Quantity"
                value={section.Quantity}
                onChange={(event) => handleChangeItem(event, index)}
              />
            </Box>
            <Box sx={{ marginInline: "5px" }}>
              <Typography>Unit Price</Typography>
              <TextField
                className={classes.input}
                sx={{ margin: "10px" }}
                fullWidth
                name="UnitPrice"
                value={section.UnitPrice}
                onChange={(event) => handleChangeItem(event, index)}
              />
            </Box>
          </Box>
          {/* Add the remove button */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginTop: '27px' }}>
            <img
              src="/assets/images/minus.png" // Path to your minus icon
              alt="Remove"
              width={"30px"}
              height={"30px"}
              onClick={() => handleRemoveSection(index)}
              style={{ cursor: "pointer", borderRadius: '50%' }} // Round the button
            />
          </Box>
        </Box>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}><img
        src="/assets/images/plus.png"
        alt="My Icon"
        width={"2%"}
        height={"2%"}

        onClick={handleAddSection}
        style={{ cursor: "pointer", alignSelf: 'center' }}
      /></Box>

      <Box display={"flex"} sx={{ marginTop: "20px" }}>
        {/* <Typography>Exchange Policy </Typography>
        <Typography sx={{ color: "blue" }}>
          ( 14 days ) 1-3 are eligible for an exchange or credit{" "}
        </Typography> */}
      </Box>
      <Box>
        <TextField
          className={classes.input}
          sx={{ marginTop: "10px " }}
          multiline
          name="Discription"
          onChange={handleChange}
          value={PDFstates.Discription}
          rows={8}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          marginTop: "50px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{ fontWeight: "bold", fontSize: "26px" }}
        >
          Zenon Interactive L.L.C
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{ fontWeight: "bold", fontSize: "26px" }}
        >
          dba
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{ fontWeight: "bold", fontSize: "26px" }}
        >
          Zenon Wholesale Digital Marketing
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{ fontWeight: "bold", fontSize: "26px", color: "red" }}
        >
          Incoming Wiring Instructions:
        </Typography>
        <Box sx={{ alignItems: "center" }}>
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "bold", fontSize: "22px" }}
          >
            Beneficiary Name:
          </Typography>
          <TextField
            fullWidth
            name="beneficiaryName"
            onChange={handleChange}
            value={PDFstates.beneficiaryName}
            className={classes.input}
            sx={{ marginTop: "10px" }}
          />
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "bold", fontSize: "22px", marginTop: "10px" }}
          >
            Chase Bank Wiring Address:
          </Typography>
          <TextField
            fullWidth
            name="chaseBank"
            onChange={handleChange}
            value={PDFstates.chaseBank}
            className={classes.input}
            sx={{ marginTop: "10px" }}
          />
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "bold", fontSize: "22px", marginTop: "10px" }}
          >
            Zenon Interactive LLC Account Number:
          </Typography>
          <TextField
            fullWidth
            name="ZenonAC"
            onChange={handleChange}
            value={PDFstates.ZenonAC}
            className={classes.input}
            sx={{ marginTop: "10px" }}
          />
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "bold", fontSize: "22px", marginTop: "10px" }}
          >
            Zenon Interactive LLC Routing Number:
          </Typography>
          <TextField
            fullWidth
            className={classes.input}
            name="ZenonRN"
            onChange={handleChange}
            value={PDFstates.ZenonRN}
            sx={{ marginTop: "10px" }}
          />
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "bold", fontSize: "22px", marginTop: "10px" }}
          >
            Beneficiary Bank Name:
          </Typography>
          <TextField
            fullWidth
            name="beneficiaryBankName"
            onChange={handleChange}
            value={PDFstates.beneficiaryBankName}
            className={classes.input}
            sx={{ marginTop: "10px" }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default CreateInvoice2;

